import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "src/ui-treact/components/misc/Headings";

// const Container = tw.div`flex flex-col justify-center items-center w-full p-16 bg-brand-green
// `;
const Container = styled.div`
  background-image: url(${props=>props.background_image});
  background-color: #fff;
  position: relative;
  width: 100%;
  background-position: top;
  background-size: 100% 1180px;
  background-repeat: no-repeat;
  height: 1190px;
  margin-top: -10%;
  padding-top: 20%;
  margin-bottom: ${props=>props.withFacts ? '-12%' : '-12%'};
  @media (max-width: 768px) {
    background-color: #ecf4d96b;
    margin-bottom: 2%;
    height: auto;
    padding-bottom: 20px;
  }
  ${props=>props.custom_css}
`;

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto `;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0 px-8 text-justify`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);


const TextContent = tw.div`flex flex-col justify-center items-center lg:pt-16 w-full`;

const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Subheading = tw(SubheadingBase)`text-center md:text-center text-white font-normal  mb-0 text-black`;
const Description = tw.p`text-sm md:text-base lg:text-base font-normal leading-relaxed text-secondary-100 mt-4 text-gray-600`


export default ({data, page}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  let finalData = data?.schema_json;  
  let content= {
    ...finalData
}  

// console.log('finalData', finalData)
  return (
    <Container background_image={content.background_image} withFacts={content.withFacts} custom_css={content.custom_css}>
        <TextContent>
            <Subheading>{content.sub_heading}</Subheading>
            <Heading>{content.heading}</Heading>
        </TextContent>
      <TwoColumn>
        <TextColumn textOnLeft={content.textOnLeft}>
                    <Description dangerouslySetInnerHTML={{__html:finalData.left_text}}></Description>
        </TextColumn>
        <TextColumn textOnLeft={content.textOnLeft}>
            <Description dangerouslySetInnerHTML={{__html:finalData.right_text}}></Description>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
